body {
  overflow: hidden;
  @media (max-width: 600px) {
    overflow:auto;
  }
}

.App {
  text-align: center;
}
